import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './styles/index.scss';

import Home from './Home';
import Navigation from './components/Navigation';
import AboutUs from "./components/AboutUs";
import Services from './components/Services';
import Bootfitting from './components/Bootfitting';
import Partners from './components/Partners';
import Aanbiedingen from "./components/Aanbiedingen";
import Contact from './components/Contact';
import Footer from './components/Footer';

import * as serviceWorker from './serviceWorker';

const Routing = (
    <Router>
        <div>
            <Navigation/>
            <Home/>
            <AboutUs/>
            <Services/>
            <Bootfitting/>
            <Partners/>
            <Aanbiedingen/>
            <Contact/>
            <Footer/>
        </div>
    </Router>
)

ReactDOM.render(Routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
