import React from 'react';

import AboutUsPic from '../img/norbert_carlo_store.jpg';

export default class AboutUs extends React.Component {
    render() {
        return (
            <div id="about-us" className="section">
                <h1 className="section-title">Over ons</h1>
                <div className="about_us_content">
                    <div className="about_us_txt">
                        <h4>Welkom bij Norbert Maier Sport!</h4>
                        <p>
                            Norbert Maier Sport wordt gerund door Norbert Maier, Norbert kwam in 1982 vanuit het
                            Oostenrijkse Lofer in het Salzburgerland naar Rotterdam. Na een zeer ruime ervaring opgedaan
                            te hebben en een goede naam gemaakt te hebben in de sportbranche (bij zowel grote
                            Oostenrijkse en Nederlandse bedrijven), is hij nu alweer zo’n 28 jaar actief in de winkel
                            aan de voet van de Erasmusbrug te Rotterdam.
                        </p>
                        <br/>
                        <h4>Uw specialist</h4>
                        <p>
                            Norbert Maier Sport is gespecialiseerd in met name wintersport artikelen. Met meer dan 85
                            verschillende modellen ski’s hebben wij voor elk niveau iets wils. Wat betreft skischoenen
                            ligt onze expertise in het op maat maken van schoenen en bootfitting. Hierdoor proberen wij
                            onze klant een schoen te bieden met een goede aansluiting en pijnvrij. Onze kledinglijn
                            varieert van casual tot technische skikleding.
                        </p>
                        <br/>
                        <h4>Ervaring</h4>
                        <p>
                            Door onze jarenlange ervaring in divers schoeisel zijn wij de specialist voor een
                            probleemvoet en of moeilijke voeten. Hierdoor kunnen wij een goed passend product aanbieden
                            met een maximaal draagcomfort. Heeft u al een paar skischoenen maar krijgt u pijn als u
                            deze draagt? Dan kijken wij graag wat we kunnen doen om het draagcomfort te verbeteren.
                            Hiervoor hebben wij verschillende methodes in huis.
                        </p>
                    </div>
                    <div className="about_us_img">
                        <img alt="" src={AboutUsPic}/>
                    </div>
                </div>
            </div>
        )
    }
}