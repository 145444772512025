import React from "react";

import FacebookIcon from '@material-ui/icons/Facebook';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="contact">
                    <h4>Contact</h4>

                    <table className="contact_footer footer_list">
                        <tbody>
                            <tr>
                                <td className="week_day">Adres</td>
                                <td>Schiedamsedijk 155, 3011EP Rotterdam</td>
                            </tr>
                            <tr>
                                <td className="week_day">Telefoon</td>
                                <td>010 413 1167</td>
                            </tr>
                            <tr>
                                <td className="week_day">E-mail</td>
                                <td>info@norbertmaiersport.nl</td>
                            </tr>
                            <tr>
                                <td className="week_day"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/norbert.maier.sport.1"><FacebookIcon fontSize="large" /></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="store">
                    <h4>Openingstijden Winkel</h4>

                    <table className="store-times footer_list">
                        <tbody>
                            <tr>
                                <td className="week_day">maandag</td>
                                <td>gesloten</td>
                            </tr>
                            <tr>
                                <td className="week_day">dinsdag</td>
                                <td>10:00 - 18:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">woensdag</td>
                                <td>10:00 - 18:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">donderdag</td>
                                <td>10:00 - 18:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">vrijdag</td>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">zaterdag</td>
                                <td>10:00 - 17:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">zondag</td>
                                <td>11:00 - 17:00</td>
                            </tr>
                        </tbody>
                    </table>

                    <p className="footer_paragraph">Ook op afspraak geopend!</p>
                </div>
                <div className="garage">
                    <h4>Openingstijden garage Erasmusbrug</h4>

                    <table className="garage-times footer_list">
                        <tbody>
                            <tr>
                                <td className="week_day">maandag</td>
                                <td>07:00 - 23:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">dinsdag</td>
                                <td>07:00 - 23:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">woensdag</td>
                                <td>07:00 - 23:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">donderdag</td>
                                <td>07:00 - 23:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">vrijdag</td>
                                <td>07:00 - 24:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">zaterdag</td>
                                <td>07:00 - 24:00</td>
                            </tr>
                            <tr>
                                <td className="week_day">zondag</td>
                                <td>10:00 - 18:30</td>
                            </tr>
                        </tbody>
                    </table>

                    <p className="footer_paragaph">
                        Uitrijden is 24 uur per dag mogelijk
                        <br/>
                        Doorrijhoogte 1.90m
                    </p>
                </div>
            </div>
        )
    }
}