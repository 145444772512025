import React from 'react';

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";

import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from "react-scroll";

import NavData from '../data/navigation';
import Logo from '../img/norbertmaiersport-logo.gif.png';


export default class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: NavData.navigationItems,
            right: false,
            top: true
        };

        this.ToggleDrawer = this.ToggleDrawer.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const top = window.scrollY < window.innerHeight;
            if (top !== this.state.top) {
                this.setState({top})
            }
        });
    }

    ToggleDrawer = (open) => {
        this.setState({
            right: open
        })
    };

    render() {
        const menuItems = this.state.data;
        const right = this.state.right;

        return (
            <div>
                <AppBar position="fixed" classes={{root: this.state.top ? 'on_image' : 'past_image'}}>
                    <Toolbar>
                        <Link className="home_scroll"
                              to="home"
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                        >
                            <img src={Logo} alt="logo" className="navigation_logo" />
                        </Link>
                        <div className="menu_items">
                             <div className="mobile_nav">
                                    <IconButton onClick={() => this.ToggleDrawer(true)}>
                                        <MenuIcon className='menu_icon_black'/>
                                    </IconButton>
                                    {right &&
                                        <SwipeableDrawer className="mobile_menu_list" open{...right} onClose={() => this.ToggleDrawer(false)} onOpen={() => this.ToggleDrawer(true)}>
                                            <CloseIcon onClick={() => this.ToggleDrawer(false)} className="close_icon" fontSize="large"/>
                                            <Link className="home_scroll"
                                                  to="home"
                                                  spy={true}
                                                  smooth={true}
                                                  offset={-70}
                                                  duration={500}
                                                  onClick={() => this.ToggleDrawer(false)}
                                            >
                                                <img src={Logo} alt="logo" className="navigation_logo_mobilenav" />
                                            </Link>
                                            {menuItems.map((menuItem, index) =>
                                                <Link className="menu_item"
                                                      key={index}
                                                      activeClass="active"
                                                      to={menuItem.url}
                                                      spy={true}
                                                      smooth={true}
                                                      offset={-70}
                                                      duration={500}
                                                      onClick={() => this.ToggleDrawer(false)}
                                                >
                                                    {menuItem.label}
                                                </Link>
                                            )}
                                        </SwipeableDrawer>
                                    }
                                  </div>
                             <div className="desktop_nav">
                                {menuItems.map((menuItem, index) =>
                                <Link className="menu_item"
                                      key={index}
                                      activeClass="active"
                                      to={menuItem.url}
                                      spy={true}
                                      smooth={true}
                                      offset={-70}
                                      duration={500}
                                >
                                    {menuItem.label}
                                </Link>
                                )}
                              </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}