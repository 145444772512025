import React from 'react';

import IndoorSki from '../img/indoor_ski.jpeg';
import Lofer from '../img/almenwelt-lofer.jpeg';
import Saalbach from '../img/Saalbach.png';
import Theresia from '../img/Haus-Theresia.gif';

export default class Partners extends React.Component {
    render() {
        return (
            <div id="partners" className="section">
                <h1 className="section-title">Partners</h1>
                <p className="partners_txt">
                    Binnen ons assortiment werken wij met verschillende merken waarin wij veel vertrouwen hebben.
                    Met dit assortiment hopen wij alles in huis te hebben om onze klanten te bedienen.
                    Wij zijn officieel dealer van: Fischer, Head, Rossignol, Salomon, Atomic, Elan, Kästle, Blizzard,
                    K2, Fulltilt, Bootdoc, Sidas, Superfeet, KASK, Giro, STX, HMR Helmets, Sinner, Ziener, Leki, Reusch,
                    Decênte, Lutha, Icepeak, Killtec, Maier Sports, Spyder, Mäser Austria, Falke
                </p>

                <div className="indoor_wrapper">
                    <p className="partners_txt indoor_txt">
                        Wij benadrukken graag onze samenwerking met Indoorski & Snowboard Rotterdam. Bij aankoop van een
                        paar skischoenen krijgt u een gratis proefles t.w.v. €30,-. Hier kunt u onder professionele
                        begeleiding uw skischoenen uitproberen.
                    </p>

                    <a target="_blank" rel="noopener noreferrer" href="https://www.indoorski-rotterdam.nl/"><img className="indoor_img" alt="" src={IndoorSki} /></a>
                </div>

                <h3 className="logos_title">Bezoek ook</h3>
                <div className="logos_wrapper">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.skialm-lofer.com/"><img alt="" src={Lofer} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.lofer.com/de/"><img alt="" src={Saalbach} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.haustheresia.at/"><img alt="" src={Theresia} /></a>
                </div>
            </div>
        )
    }
}